import React from 'react';
import './style.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Data() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={{width: isMobile? '70vw' : '60vw', margin: '0 auto', paddingTop: isMobile ? '8vw' : '14vw', marginLeft: isMobile ? '15vw' : '25%'}}>
            <h3 className="pageTitle" style={{fontSize: isMobile? '20px' : '30px'}}>Managing your data.</h3>
            <p style={{textAlign: isMobile ? 'left' : 'centre  '}} className="dataText">Your baby board will contain only the data that you send to me. It will not be accessible to anyone other than me and you.</p>
            <p style={{textAlign: isMobile ? 'left' : 'centre  '}} className="dataText">The repository (the place where the code for the site will be run from) will be private. Your website will have a login page that has a password you can specify.</p>
            <p style={{textAlign: isMobile ? 'left' : 'centre  '}} className="dataText">All relevant data will be removed from my machine once the page is live. Your data will only be stored in the private GitHub repository and the deployed website.</p>
            <p style={{textAlign: isMobile ? 'left' : 'centre  '}} className="dataText">You are fully entitled to request to receive any data I hold on you, and for me to remove any data I hold on you - though once this is removed from the private GitHub repository, your website will no longer be functional.</p>
            <p style={{textAlign: isMobile ? 'left' : 'centre  '}} className="dataText">For an explanation of why this is, please check the 'How is it made' section of the FAQs.</p>
            <p style={{textAlign: isMobile ? 'left' : 'centre  '}} className="dataText">Your data will <b>NEVER</b> be disclosed to third parties other than the websites listed above for any reason.</p>
        </div>
    )
}