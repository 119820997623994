import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './style.css';

export default function NavBar(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [expand, setExpand] = React.useState(false);
    function home() {
        props.setPage('home');
        setExpand(false);
    }

    function FAQ() {
        props.setPage('FAQ');
        setExpand(false);
    }

    function stepByStep() {
        props.setPage('stepByStep');
        setExpand(false);
    }

    function data() {
        props.setPage('data');
        setExpand(false);

    }

    function example() {
        props.setPage('example');
        setExpand(false);

    }

    function expandMenu() {
        setExpand(!expand);
    }

  

    return (
        <div>

        {!isMobile && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', position: 'fixed'}}>
        <div style={{marginTop: '20vh', marginLeft: '2vw'}}>
            <p className="menuText" style={{marginTop: '5vh', marginBottom: '5vh', fontSize: isMobile? '12px' : '30px', textAlign: isMobile? 'left' : 'center'}} onClick={home}>Home</p>
            <p className="menuText" style={{marginTop: '5vh', marginBottom: '5vh', fontSize: isMobile? '12px' : '30px', textAlign: isMobile? 'left' : 'center', width: isMobile ? '20px' : '100%'}} onClick={example}>Example prototype</p>
            <p className="menuText" style={{marginTop: '5vh', marginBottom: '5vh', fontSize: isMobile? '12px' : '30px', textAlign: isMobile? 'left' : 'center'}} onClick={stepByStep}>Step by step </p>
            <p className="menuText" style={{marginTop: '5vh', marginBottom: '5vh', fontSize: isMobile? '12px' : '30px', textAlign: isMobile? 'left' : 'center'}} onClick={data}>Data</p>
            <p className="menuText" style={{marginTop: '5vh', marginBottom: '5vh', fontSize: isMobile? '12px' : '30px', textAlign: isMobile? 'left' : 'center'}} onClick={FAQ}>FAQ</p>
        </div>

        </div>}

        {isMobile && <div id="expandContainer">
            {expand && 
            <div id="expandMenu">
                <p style={{width: '25vw', textAlign: 'left'}} onClick={home}>Home</p>
                <p style={{width: '25vw', textAlign: 'left'}} onClick={example}>Example prototype</p>
                <p style={{width: '25vw', textAlign: 'left'}} onClick={stepByStep}>Step by step</p>
                <p style={{width: '25vw', textAlign: 'left'}} onClick={data}>Data</p>
                <p style={{width: '25vw', textAlign: 'left'}} onClick={FAQ}>FAQ</p>
            </div>}
            <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Hamburger_icon.svg/2048px-Hamburger_icon.svg.png"} alt="Arrow" style={{top: '5px', left: '2vw', width: `12vw`}} onClick={expandMenu} id="expandArrow" />
            
        </div>}

        </div>  
    )
}



