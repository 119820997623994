import React, {useState} from 'react';
import './style.css';
import crib from './crib.jpg'
import blocks from './blocks.jpg'
import bear from './final (1).jpg'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Info() {
    const [leftExpand, setLeftExpand] = useState(false);
    const [centreExpand, setCentreExpand] = useState(false);
    const [rightExpand, setRightExpand] = useState(false);
    function expandLeft() {
        setLeftExpand(!leftExpand);
    }

    function expandCentre() {
        setCentreExpand(!centreExpand);
    }

    function expandRight() {
        setRightExpand(!rightExpand);
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Neil email referral: neilbabyboard@gmail.com

    return (
    <div>
        {isMobile && <div>
            
            <div>
                <img src={crib} alt="crib" style={{width: '70vw', height: '40vh', marginTop: '2vh'}}/>
                <div style={{backgroundColor: '#F8F6FA', width: '70vw', margin: '0 auto', marginTop: '-4vh', borderBottomRightRadius: '5px', borderBottomLeftRadius: '5px'}}>
                    <h3 className="menuText" style={{paddingTop: '3vh'}}>What is BabyBoard?</h3>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto'}}>BabyBoard is a personalised app-based soundboard that aims to reinforce early learning through repetition. The idea is simple. You will need: </p>
                    <ul>
                        <li style={{fontSize: '13px', textAlign: 'left', width: '50vw', margin: '0 auto'}}>Some pictures of faces (mummy, daddy, siblings), animals, or anything else you'd like to reinforce the sound of for your baby.</li>
                        <li style={{fontSize: '13px', textAlign: 'left', width: '50vw', margin: '0 auto', paddingTop: '1vh'}}>An audio recording of someone whose voice your baby recognises - or even your baby, if they are old enough to form words - saying the words that are associated with these pictures.</li>
                    </ul>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '2vh'}}>Send them  to me along with a bank transaction (once I've confirmed the job) and I'll convert them into a personalised soundboard, based on a private repository, on your own private URL using the Netlify deployment platform.</p>
                </div>
            </div>

            <div>
                <img src={blocks} alt="crib" style={{width: '70vw', height: '40vh', marginTop: '2vh'}}/>
                <div style={{backgroundColor: '#F8F6FA', width: '70vw', margin: '0 auto', marginTop: '-4vh', borderBottomRightRadius: '5px', borderBottomLeftRadius: '5px'}}>
                    <h3 className="menuText" style={{paddingTop: '3vh'}}>What can you do with it?</h3>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '2vh'}}>When you're a baby, you learn in several ways. One of these ways is to play and interact, the other is repetition.</p>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '2vh'}}>It's not enough to get shown and told things. You have to do things yourself! Explore, pull that lever, press that button.</p>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '2vh'}}>When your baby plays with their own personalised soundboard, they'll be delighted to press one of the pictures that they identify with a loved one and hear a familiar voice talking to them. But at the same time, they'll be learning to associate a certain sound with a certain picture.</p>
                </div>
            </div>

            <div>
                <img src={bear} alt="crib" style={{width: '70vw', height: '40vh', marginTop: '2vh'}}/>
                <div style={{backgroundColor: '#F8F6FA', width: '70vw', margin: '0 auto', marginTop: '-4vh', marginBottom: '2vh', borderBottomRightRadius: '5px', borderBottomLeftRadius: '5px'}}>
                    <h3 className="menuText" style={{paddingTop: '3vh'}}>Prices, contact and status</h3>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '2vh'}}>Drop me an email to get started:</p>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '3vh', fontWeight: 'bold'}}>babyboard@mail.com</p>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '0.5vh'}}>Prices as follows:</p>
                    <ul>
                        <li style={{fontSize: '13px', textAlign: 'left', width: '50vw', margin: '0 auto', fontWeight: 'bold'}}>£14 for 8 buttons</li>
                        <li style={{fontSize: '13px', textAlign: 'left', width: '50vw', margin: '0 auto', paddingTop: '0.5vh', fontWeight: 'bold'}}>£16 for 10 buttons</li>
                        <li style={{fontSize: '13px', textAlign: 'left', width: '50vw', margin: '0 auto', paddingTop: '0.5vh', fontWeight: 'bold'}}>£18 for 12 buttons</li>
                    </ul>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingTop: '1vh', paddingBottom: '2vh'}}>Availability:</p>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '2vh', fontWeight: 'bold', color: 'green'}}>Ready to go!</p>
                    <p style={{fontSize: '13px', textAlign: 'justify', width: '60vw', margin: '0 auto', paddingBottom: '2vh'}}>This means you can expect your product in maximum <b>Two days.</b></p>

                </div>
            </div>
            <h5>See our reviews!</h5>
            <a href="https://uk.trustpilot.com/review/babyboard.co.uk"><img style={{width: isMobile ? '26vw' : '8vw', marginTop: '-2vw', marginBottom: '3vh'}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Trustpilot_Logo_%282022%29.svg/1200px-Trustpilot_Logo_%282022%29.svg.png" alt="trustpilot" /></a>


        </div>}




    {!isMobile && 
        <div id="ultraContainer" style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', gap: '12vw', marginRight: '5vw'}}>
            
        <div onClick={expandLeft} style={{marginTop: '20vh', position: 'relative'}}>
                <img className="desktopImages" src={crib} alt="crib" style={{width: isMobile ? '40vw' : '16vw', height: 'auto', marginTop: isMobile ? '1vw' : '8vh'}}/>
                <h1 className="containingText" style={{marginTop: '-40vh', color: 'black'}}>What is BabyBoard?</h1>
                    {leftExpand && <div className="expand" style={{position: 'fixed', marginTop: '-19.5vh'}}>
                        <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white', marginLeft: '16vw', width: '11vw', fontSize: '13px', textAlign: 'left'}}>
                            <p className="expandedText" style={{marginLeft: '0.5vw', width: '10vw'}}>BabyBoard is a personalised app-based soundboard that aims to reinforce early learning through repetition. The idea is simple. You will need: </p>
                            <ul>
                                <li className="expandedText" style={{width: '8vw'}}>Some pictures of faces (mummy, daddy, siblings), animals, or anything else you'd like to reinforce the sound of for your baby.</li>
                                <li className="expandedText" style={{width: '7vw', marginTop: '1.5vh'}}>An audio recording of someone whose voice your baby recognises - or even your baby! saying the words that are associated with these pictures.</li>
                            </ul>
                            <p  className="expandedText" style={{marginBottom: '1.8vh', marginLeft: '0.5vw'}}>Send them to me along with a bank transaction (once I've confirmed the job) and I'll convert them into a personalised soundboard, based on a private repository, on your own private URL using the Netlify deployment platform.</p>
                        </div>
                    </div>}

            </div>
            
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <div onClick={expandCentre} style={{position: 'relative', marginTop: '0.1vh'}}>
                <img className="desktopImages" src={blocks} alt="blocks" style={{width: '16vw', height: '20vw', marginTop: '8vh'}}/>
                <h1 className="containingText" style={{marginTop: '-28vh', color: 'black'}}>What can you do with it?</h1>
                {centreExpand && <div className="expand" style={{position: 'fixed', marginTop: '-22.5vh'}}>
                <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white', marginLeft: '16vw', width: '11vw', fontSize: '13px', textAlign: 'left'}}>
                            <p className="expandedText" style={{marginLeft: '0.5vw', width: '10vw'}}>When you're a baby, you learn in several ways. One of these ways is to play and interact, the other is repetition.</p>
                            <p  className="expandedText" style={{width: '10vw', marginLeft: '0.5vw'}}>It's not enough to get shown and told things. You have to do things yourself! Explore, pull that lever, press that button.</p>
                            <p className="expandedText" style={{width: '10vw', marginLeft: '0.5vw', marginBottom: '2.5vh'}}>When your baby plays with their own personalised soundboard, they'll be delighted to press one of the pictures that they identify with a loved one and hear a familiar voice talking to them. But at the same time, they'll be learning to associate a certain sound with a certain picture.</p>
                        </div>
                    </div>}

            </div>
            <div onClick={expandRight} style={{position: 'relative', marginTop: '18vh'}}>
                <img className="desktopImages" src={bear} alt="bear" style={{width: '16vw', height: '20vw', marginTop: '8vh'}}/>
                <h1 className="containingText" style={{marginTop: '-28vh', color: 'black'}}>Prices, contact and status</h1>
                {rightExpand && <div className="expand" style={{position: 'fixed', marginTop: '-21.5vh'}}>
                <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white', marginLeft: '16vw', width: '11vw', fontSize: '13px', textAlign: 'left'}}>
                            <p className="expandedText" style={{marginLeft: '0.5vw', width: '10vw', marginBottom: '0vh'}}>Drop me an email to get started:</p>
                            <p  className="expandedText" style={{width: '10vw', marginLeft: '0.5vw', fontWeight: 'bold'}}>neil@babyboard.co.uk</p>
                            <p  className="expandedText" style={{width: '10vw', marginLeft: '0.5vw', marginBottom: '0vh'}}>Prices as follows:</p>
                            <ul>
                                <li className="expandedText" style={{width: '8vw', fontWeight: 'bold'}}>£14 for 8 buttons</li>
                                <li className="expandedText" style={{width: '7vw', marginTop: '1.5vh', fontWeight: 'bold'}}>£16 for 10 buttons</li>
                                <li className="expandedText" style={{width: '7vw', marginTop: '1.5vh', fontWeight: 'bold'}}>£18 for 12 buttons</li>
                            </ul>
                            <p className="expandedText" style={{width: '10vw', marginLeft: '0.5vw',marginBottom: '0vh'}}>Availability:</p>
                            <p className="expandedText" style={{width: '10vw', marginLeft: '0.5vw', marginBottom: '0vh', fontWeight: 'bold',}}>Ready to go!</p>
                            <p className="expandedText" style={{width: '10vw', marginLeft: '0.5vw', marginBottom: '2.5vh'}}>This means you can expect your product in maximum <b>Two days.</b></p>
                        </div>
                    </div>}
            </div>
            </div>

            <div style={{position: 'fixed', top: '25vh', right: '5vw', border: '3px inset rgb(40, 120, 120)', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px'}}>
                <h4>See our reviews!</h4>
                <a href="https://uk.trustpilot.com/review/babyboard.co.uk"><img style={{width: isMobile ? '26vw' : '8vw', marginTop: '0.2vw'}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Trustpilot_Logo_%282022%29.svg/1200px-Trustpilot_Logo_%282022%29.svg.png" alt="trustpilot" /></a>
            </div>
            
        </div>}
        
    </div>
    )
}