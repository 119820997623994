import './App.css';
import NavBar from '../NavBar';
import { useState } from 'react';
import FAQ from '../FAQ';
import Data from '../Data';
import Landing from '../Landing';
import Info from '../Info';
import Example from '../Example';
import StepByStep from '../StepByStep';



function App() {
  const [page, setPage] = useState('home');
  const [entered, setEntered] = useState(false);


  return (
    <div className="App">

      {!entered && <Landing setEntered={setEntered}/>}

      {entered && <div>
      <NavBar page={page} setPage={setPage}/>
      {page === 'home' &&
      <Info />
      }
      {page === 'data' &&
      <Data />
      }
      {page === 'stepByStep' &&
      <StepByStep />}
      {page === 'example' &&
      <Example />
      }
      {page === 'FAQ' &&  
      <FAQ />
      }
      </div>}
    </div>
  );
}

export default App;
