import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function StepByStep() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={{width: '60vw', marginLeft: '20vw', marginTop: isMobile ? '5vh' : '15vh', position: isMobile ? 'inherit' : 'fixed'}}>
            <h1 class="menuText" style={{textAlign: 'justify'}}>Step by step guide</h1>
            <p style={{fontSize: isMobile ? '14px' : '18px', textAlign: 'justify'}}>Here's a step by step guide to the process of getting your own Babyboard.</p>
            <p style={{fontSize: isMobile ? '14px' : '18px', textAlign: 'justify'}}>Step 1: You email me to get the ball rolling on your personalised babyboard. As a good guide, your initial email should contain the following:</p>
            <p className="dataText" style={{marginBottom: '1vh', fontWeight: 'bold', textAlign: 'justify', fontSize: isMobile ? '13px' : '16px'}}> - A note of how many buttons you want between 8, 10, and 12.</p>
            <p className="dataText" style={{marginBottom: '1vh', fontWeight: 'bold', textAlign: 'justify', fontSize: isMobile ? '13px' : '16px'}}> - Photos of the people/other you'd like turned into buttons.</p>
            <p className="dataText" style={{marginBottom: '1vh', fontWeight: 'bold', textAlign: 'justify', fontSize: isMobile ? '13px' : '16px'}}> - Audio recordings. These can either be separate audio recordings or one containing all - whichever you'd prefer, although it's important to clearly match photographs to sounds.</p>
            <p className="dataText" style={{marginBottom: '1vh', fontWeight: 'bold', textAlign: 'justify', fontSize: isMobile ? '13px' : '16px'}}> - The device you'd like them optimised for. Generally, the Babyboard should look good on any device, but it may make sense on certain tablets etc to have a different number of buttons on each row.</p>
            <p className="dataText" style={{marginBottom: '1vh', fontWeight: 'bold', textAlign: 'justify', fontSize: isMobile ? '13px' : '16px'}}> - The password you'd like to have for your Babyboard.</p>
            <p className="dataText" style={{marginBottom: '1vh', fontWeight: 'bold', textAlign: 'justify', fontSize: isMobile ? '13px' : '16px'}}> - Your child's favourite colour, or a colour you'd like to have. The default is yellow as per this site.</p>
            <p style={{fontSize: isMobile ? '12px' : '18px', textAlign: 'justify'}}>Step 2: I'll send an email back, confirming the job, the price and providing an invoice. Once the invoice is paid and received by me, I'll send a confirmation. The next thing you'll receive is the link and password to your new site!</p>
        </div>
    )
} 