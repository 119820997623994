import React from 'react';
import './style.css';


export default function FAQ() {


    return (

        <div>
            <h1   className="pageTitle">FAQ</h1>
            <div style={{width: '70vw', margin: '0 auto'}}>
            <p className="headerText" >Who are you?</p>
            <p className="faqText">Hi! I'm Neil Brooks. I'm a software engineer who studied at the School of Code. I made a soundboard just like this one for my nephew and he loved it. After I mentioned the idea to a couple of people, they said that it would make a great gift.</p>
            <p  className="faqText">If you'd like to learn more about me, feel free to visit my <a href="https://neil-brooks-portfolio.netlify.app/">personal site.</a></p>
            <p  className="headerText">How do I know it's legitimate?</p>
            <p  className="faqText">As you'll see on my main page, I'm registered with Trustpilot, so any negative actions on my part would quickly be reflected in my reviews. I've also verified my identity using the same platform. </p>
            <p  className="headerText">How is it made?</p>
            <p  className="faqText">Your BabyBoard has been created using React, a library of the programming language Javascript. In order for the code to be run, it is stored in a repository on GitHub. A repository is a place where code is stored. GitHub is a website that allows the user to create and update these repositories.</p>
            <p  className="faqText">Finally, the site uses a deployment platform - in this case Netlify. Netlify reads and runs the code stored in the repository.</p>
            <p  className="headerText">How does the process go?</p>
            <p  className="faqText" >The first thing to do if you'd like a BabyBoard is check my availability updates on the main page - just in case there's anything preventing me from getting your product out to you ASAP.</p>
            <p  className="faqText" >Next you can send me an email, detailing the product you'd like, and providing two key sets of information: Photos of the people/other you'd like turned into buttons, and audio recordings. These can either be separate audio recordings or one containing all - whichever you'd prefer, although it's important to clearly match photographs to sounds.</p>
            <p  className="faqText" >I'll send you a confirmation email as well as an estimated time of completion. This shouldn't be any longer than <b>two days</b> if my status is listed as 'ready to go'. I'll also include an invoice for the price and BACS details.</p>
            <p  className="faqText" >Once I've received the transfer, I'll confirm this via email also. The next email you'll get will contain the URL of your BabyBoard and a password for access.</p>
            <p  className="faqText">Once you've received this, you can log in and your baby can play with their BabyBoard to their heart's content!</p>
            <p  className="headerText">What if I want to change something?</p>
            <p  className="faqText" >If you're unhappy how an image looks on its button or how something sounds, let me know and I'll be happy to amend it. One thing you won't be able to do, however, to do is to change the overall content of the buttons or make continuous changes.</p>
            <p  className="headerText">What if I'm unhappy?</p>
            <p  className="faqText">I'll be happy to offer you a full refund provided you let me know within one month of purchase. This can be for <b>any</b> reason, from software issues to your baby simply not liking it.</p>
            <p  className="faqText">You'll receive a full refund and the site will be taken down.</p>
            </div>  
        </div>
    )
}