import React from 'react';
import './style.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Landing(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    function proceed() {
        props.setEntered(true);
    }

    return (
        <div style={{width: isMobile? '80vw' : '100vw', margin: '0 auto' }}>
            <h1 className="pageTitle" style={{marginTop: '25vh', fontSize: isMobile? '50px' : '100px'}}>BabyBoard</h1>
            <h3 className="pageTitle" style={{fontSize: isMobile ? '25px' : '50px', marginTop: isMobile ? '-1vh' : '-5vh'}}>Language learning and fun for your baby!!</h3>
            <h5 onClick={proceed} className="pageTitle" style={{fontSize: isMobile ? '28px' : '30px'}}>Begin</h5>
        </div>
    )
}