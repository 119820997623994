import React from 'react';
import Box from '@mui/material/Box';
import useSound from 'use-sound';
import daddysound from './daddysound.m4a'
import daddy from './daddy.jpg'
import mummy from './mummy.jpg'
import mummysound from './mummysound.m4a'
import dog from './dog.JPG'
import dogsound from './dogsound.m4a'
import cat from './cat.JPG'
import catsound from './catsound.m4a'
import sheep from './sheep.JPG'
import sheepsound from './sheepsound.m4a'
import horse from './horse.JPG'
import horsesound from './horsesound.m4a'
import granny from './granny.JPG'
import grannysound from './grannysound.m4a'
import grandad from './grandad.JPG'
import grandadsound from './grandadsound.m4a'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Soundboard1() {

    const [play1] = useSound(daddysound);
    const [play2] = useSound(mummysound);
    const [play3] = useSound(grannysound);
    const [play4] = useSound(grandadsound);
    const [play5] = useSound(dogsound);
    const [play6] = useSound(catsound);
    const [play7] = useSound(sheepsound);
    const [play8] = useSound(horsesound);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [size, setSize] = React.useState('small');

    function daddySound() {
        play1(daddysound);
          };

    function mummySound() {
        play2(mummysound);
          }

    function grannySound() {
        play3(grannysound);
          }

    function grandadSound() {
        play4(grandadsound);
          }

    function dogSound() {
        play5(dogsound);
          }

    function catSound() {
        play6(catsound);
          }

    function sheepSound() {
        play7(sheepsound);
          }

    function horseSound() {
        play8(horsesound);
          }

    function smallButtons() {
        setSize('small');
          }

    function largeButtons() {
        setSize('large');
          }
    
    return (
        <div>
            <Box className="Soundboard1">
                <p style={{fontSize: '13px', width: '64vw', margin: '0 auto', paddingTop: '2vh'}}>Below is an example soundboard that you can use to get an idea of the product.</p>
                <p style={{fontSize: '13px', width: '64vw', margin: '0 auto', paddingTop: '1.5vh'}}>Click the buttons to hear the sounds associated with that image for your baby.</p>
                <p style={{fontSize: '13px', width: '64vw', margin: '0 auto', paddingTop: '1.5vh', marginBottom: '-2vh'}}>This was done using AI art and my own voice as an example - you can put a lot more personality into yours!</p>
                <div style={{marginTop: '5vh', marginBottom: '-5vh', display: 'flex', flexDirection: 'row', gap: isMobile ? '4vw' : '2vw', justifyContent: 'center'}}>
                    <button className="buttonSizer" onClick={smallButtons} style={{border: '1px groove white', borderRadius: '50%', width: isMobile? '12vw' : '4vw', height: isMobile? '12vw' : '4vw', fontSize: '8px', textAlign: 'center', fontWeight: 'bold'}}>Small buttons</button>
                    <button className="buttonSizer" onClick={largeButtons} style={{border: '1px groove white', borderRadius: '50%', width: isMobile? '14vw' : '4.4vw', height: isMobile? '14vw' : '4.4vw', fontSize: '10px', textAlign: 'center', fontWeight: 'bold'}}>Large buttons</button>
                
                </div>
                {isMobile && <div>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', gap: '20px'}}>
                    <img onClick={daddySound} style={{width: size === 'small' ? '32vw' : '40vw', height: size === 'small' ? '32vw' : '40vw', borderRadius: '50%', border: '3px solid white', marginTop: '10px'}}  src={daddy} alt="Leo" />
                    <img onClick={mummySound} style={{width: size === 'small' ? '32vw' : '40vw', height: size === 'small' ? '32vw' : '40vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}}  src={mummy} alt="cuggle" />
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', gap: '20px', mt: '-30px'}}>
                    <img onClick={grannySound} style={{width: size === 'small' ? '32vw' : '40vw', height: size === 'small' ? '32vw' : '40vw',  borderRadius: '50%', border: '3px solid white', marginTop: '50px'}} src={granny} alt="granny" />
                    <img onClick={grandadSound} style={{width: size === 'small' ? '32vw' : '40vw', height: size === 'small' ? '32vw' : '40vw',  borderRadius: '50%', border: '3px solid white', marginTop: '50px'}}  src={grandad} alt="granddad" />
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', gap: '20px', mt: '-30px'}}>
                    <img onClick={dogSound} style={{width: size === 'small' ? '32vw' : '40vw', height: size === 'small' ? '32vw' : '40vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}} src={dog} alt="Dog" />
                    <img onClick={catSound} style={{width: size === 'small' ? '32vw' : '40vw', height: size === 'small' ? '32vw' : '40vw',  borderRadius: '50%', border: '3px solid white', marginTop: '50px'}}  src={cat} alt="cat" />
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', gap: '20px', mt: '-30px'}}>
                    <img onClick={sheepSound} style={{width: size === 'small' ? '32vw' : '40vw', height: size === 'small' ? '32vw' : '40vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}}  src={sheep} alt="sheep" />
                    <img onClick={horseSound} style={{width: size === 'small' ? '32vw' : '40vw', height: size === 'small' ? '32vw' : '40vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}} src={horse} alt="horse" />
                </Box>
                </div>}
                {!isMobile && <div>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', gap: '20px', mt: '6vh'}}>
                        <img className="menuText" onClick={daddySound} style={{width: size === 'small' ? '12vw' : '16vw', height: size === 'small' ? '12vw' : '16vw', borderRadius: '50%', border: '3px solid white', marginTop: '10px'}}  src={daddy} alt="Leo" />
                        <img className="menuText"  onClick={mummySound} style={{width: size === 'small' ? '12vw' : '16vw', height: size === 'small' ? '12vw' : '16vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}}  src={mummy} alt="cuggle" />
                        <img className="menuText"  onClick={grannySound} style={{width: size === 'small' ? '12vw' : '16vw', height: size === 'small' ? '12vw' : '16vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}} src={granny} alt="granny" />
                        <img className="menuText"  onClick={grandadSound} style={{width: size === 'small' ? '12vw' : '16vw', height: size === 'small' ? '12vw' : '16vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}}  src={grandad} alt="granddad" />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', gap: '20px'}}>
                    <img className="menuText" onClick={dogSound} style={{width: size === 'small' ? '12vw' : '16vw', height: size === 'small' ? '12vw' : '16vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}} src={dog} alt="Dog" />
                    <img className="menuText" onClick={catSound} style={{width: size === 'small' ? '12vw' : '16vw', height: size === 'small' ? '12vw' : '16vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}}  src={cat} alt="cat" />
                    <img className="menuText" onClick={sheepSound} style={{width: size === 'small' ? '12vw' : '16vw', height: size === 'small' ? '12vw' : '16vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}}  src={sheep} alt="sheep" />
                    <img className="menuText"onClick={horseSound} style={{width: size === 'small' ? '12vw' : '16vw', height: size === 'small' ? '12vw' : '16vw', borderRadius: '50%', border: '3px solid white', marginTop: '50px'}} src={horse} alt="horse" />
                    </Box>
                </div>}


            
            </Box>
        </div>
    );
    }